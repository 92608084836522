import React from "react";
import { Button, Container } from "@material-ui/core";
import { useNavigate } from "react-router";
import moment from 'moment';

function getTaskTitles(selectedAppointment) {
    const taskTitles = [];
    for (const shiftId in selectedAppointment.tasks) {
        const shift = selectedAppointment.tasks[shiftId];
        if (shift && Array.isArray(shift.tasksList)) {
            shift.tasksList.forEach((task) => {
                if (task.title) {
                    taskTitles.push(task.title);
                }
            });
        }
    }
    return taskTitles;
}

const ShiftDetails = ({ selectedAppointment }) => {
    const titles = selectedAppointment ? getTaskTitles(selectedAppointment) : [];
    const itemsPerColumn = Math.ceil(titles.length / 4);

    const columns = [];
    for (let i = 0; i < 4; i++) {
        const columnItems = titles.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn);
        columns.push(columnItems);
    }

    return (
        <div className="detail-item">
            <div className="detail-label">Shift Details</div>
            <div className="detail-value">
                <div className="shift-grid">
                    {titles.map((title, index) => (
                        <div
                            key={index}
                            className="mv-5 ml-0 mr-5"
                        >
                            {title}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
  
export function AppointmentModal({
    selectedAppointment,
    isMobile,
    isLandscape,
    clientDetails,
    employeeDetails,
    appoinments,
    auth,
    setIsModalOpen,
    setCancelAppt
}) {

    const navigate = useNavigate();

    const clientId = selectedAppointment.apoointmentDetails?.clientId ? selectedAppointment.apoointmentDetails?.clientId : selectedAppointment?.clientId;
    const clientData = clientDetails?.find((client) => client.id === clientId);

    const createdBy = selectedAppointment.apoointmentDetails.scheduledBy;
    const userData = employeeDetails?.data?.find(
        (emp) => emp.email === createdBy
    );

    const inactiveEmp = employeeDetails?.data?.find((emp)=> emp?.userId === selectedAppointment?.userId);
    const inputDate = new Date(selectedAppointment.apoointmentDetails.date);
    const formattedDate = inputDate.toLocaleDateString("en-GB", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
    });
    const month = inputDate.toLocaleString("en-US", { month: "short" });
    const day = inputDate.getDate();
    const taskArr = Object.keys(selectedAppointment.tasks);
    const shiftName = selectedAppointment?.tasks[taskArr[0]]?.title;

    const findMatchingAppointments = () => {
        if (!selectedAppointment) return [];
    
        if (selectedAppointment.apoointmentDetails.type === "Recurring Appointment") {
            return appoinments.filter((appointment) => {
                return (
                    appointment.apoointmentDetails.appointmentTypeID === selectedAppointment.apoointmentDetails.appointmentTypeID &&
                    appointment.apoointmentDetails.type === selectedAppointment.apoointmentDetails.type &&
                    appointment.apoointmentDetails.duration === selectedAppointment.apoointmentDetails.duration &&
                    appointment.apoointmentDetails.numberOfTimes === selectedAppointment.apoointmentDetails.numberOfTimes &&
                    appointment.apoointmentDetails.frequency === selectedAppointment.apoointmentDetails.frequency &&
                    appointment.apoointmentDetails.clientId === selectedAppointment.apoointmentDetails.clientId &&
                    appointment.apoointmentDetails.calendar === selectedAppointment.apoointmentDetails.calendar &&
                    appointment.apoointmentDetails.scheduledBy === selectedAppointment.apoointmentDetails.scheduledBy
                );
            });
        } else {
            return [selectedAppointment];
        }
    };

    const matchAppointmentsData = findMatchingAppointments();
    const matchingAppointments = matchAppointmentsData.sort((a, b) => new Date(a.date) - new Date(b.date));
    const currentDateTime = moment().utc();
    const appointmentDateTime = moment.utc(
      `${selectedAppointment?.apoointmentDetails?.date} ${selectedAppointment?.apoointmentDetails?.time}`, 
      "MMMM DD, YYYY hh:mm A" 
    );
    const isDateValid = appointmentDateTime.isSameOrAfter(currentDateTime);
    
    const timeZones = [
        { label: "EDT - Eastern", value: "America/New_York" },
        { label: "CDT - Central", value: "America/Chicago" },
        { label: "MDT - Mountain", value: "America/Denver" },
        { label: "PDT - Pacific", value: "America/Los_Angeles" }
    ];

    return (
        <>
            <div>
                <Container style={{ position: "relative" }}>
                    <div className="stickyHeader">
                        <div className="d-flex flex-space-between flex-center">
                            <div className="flex-start">
                                <Button className="modal-close link"
                                    onClick={() => {
                                        setIsModalOpen(false);
                                        auth.setUpdateAppointmentData({});
                                    }}>
                                    Close
                                </Button>
                            </div>
                            <div className="d-flex">
                                <div className="mr-20">
                                    <Button
                                        variant="contained"
                                        style={{ 
                                            backgroundColor: selectedAppointment.source === "proteams" &&  isDateValid &&  inactiveEmp.status !== false ? "#f62d51" : "gray",
                                            color: "white", 
                                            boxShadow: 'none', 
                                            paddingLeft: "20px", 
                                            paddingRight: "20px" }}
                                        onClick={() => {
                                            setCancelAppt(true);
                                            auth.setEditAppointmentData({ selectedAppointment });
                                            auth.setUpdateAppointmentData({ matchingAppointments });
                                        }}
                                        disabled={selectedAppointment.source !== "proteams" || !isDateValid || inactiveEmp.status === false}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: selectedAppointment.source === "proteams" && isDateValid ? "green" : "gray",
                                            color: "white",
                                            boxShadow: 'none',
                                        }}
                                        onClick={() => {
                                            setIsModalOpen(false);
                                            navigate(`/appoinmentSchedule/${selectedAppointment?.id}`);
                                            auth.setUpdateAppointmentData({ matchingAppointments });
                                            auth.setIsExpanded(false);
                                        }}
                                        disabled={selectedAppointment.source !== "proteams" || !isDateValid}
                                    >
                                        ReSchedule
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "1px",
                                backgroundColor: "#ddd",
                            }}
                            className="mb-20 mt-10"
                        ></div>
                    </div>

                    <div>
                        <div className="details-grid">
                            <div className="details-column">
                                <div className="appointment-header">
                                    <div class="date-icon">
                                        <div class="date-icon-top-lines">
                                            <div class="line">
                                                <div class="inner-line"></div>
                                            </div>
                                            <div class="line">
                                                <div class="inner-line"></div>
                                            </div>
                                            <div class="line">
                                                <div class="inner-line"></div>
                                            </div>
                                        </div>
                                        <div class="date-icon-day">{day}</div>
                                        <div class="date-icon-month">{month}</div>
                                    </div>

                                    <div>
                                        <h2 className="appointment-title">
                                            {clientData?.clientName}
                                        </h2>
                                        <div className="appointment-datetime">
                                            {formattedDate}
                                            <br />
                                            <span style={{ color: "#484848", fontWeight: "600" }}>
                                                {selectedAppointment.apoointmentDetails?.time} -{" "}
                                                {selectedAppointment.apoointmentDetails?.endTime}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`${isMobile || isLandscape
                                        ? "d-flex flex-direction-column"
                                        : "d-flex"
                                        }`}
                                >
                                    <div className="detail-item">
                                        <div className="detail-label">Phone</div>
                                        <div className="detail-value">
                                            {clientData?.contact[0]?.mobileNumber}
                                        </div>
                                    </div>
                                    <div
                                        className={`${isMobile || isLandscape
                                            ? "detail-item"
                                            : "detail-item ml-25"
                                            }`}
                                    >
                                        <div className="detail-label">Email</div>
                                        <div className="detail-value">
                                            {clientData?.contact[0]?.email}
                                        </div>
                                    </div>
                                    <div
                                        className={`${isMobile || isLandscape
                                            ? "detail-item"
                                            : "detail-item ml-25"
                                            }`}
                                    >
                                        <div className="detail-label">Client Time Zone</div>
                                        <div className="detail-value">{timeZones.find((item) => item.value === clientData.timeZone)?.label}</div>
                                    </div>
                                </div>
                                <div className="detail-item">
                                    <div className="detail-label">Client Address</div>
                                    <div className="detail-value">
                                        {selectedAppointment?.apoointmentDetails?.forms?.[0]?.values
                                            ?.filter((item) => ["Address", "State", "City", "ZipCode"].includes(item.name))
                                            ?.map((item) => item.value || "N/A")?.join(", ")}
                                    </div>
                                </div>
                                <ShiftDetails selectedAppointment={selectedAppointment} />
                            </div>

                            <div className="details-column right">
                                <div className="d-flex flex-space-between">
                                    <div className="detail-item">
                                        <div className="detail-label2">Appointment Type</div>
                                        <div className="detail-value2">
                                            {selectedAppointment.apoointmentDetails?.type}
                                        </div>
                                    </div>
                                    <div className="detail-item ml-10" style={{ width: "50%" }}>
                                        <div className="detail-label2">Employee Name</div>
                                        <div className="detail-value2">
                                            {selectedAppointment.apoointmentDetails?.calendar}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-space-between">
                                    <div className="detail-item">
                                        <div className="detail-label2">Shift Name</div>
                                        <div className="detail-value2">
                                            {selectedAppointment.apoointmentDetails?.shiftName  || shiftName}
                                        </div>
                                    </div>
                                    <div className="detail-item ml-10" style={{ width: "50%" }}>
                                        <div className="detail-label2">Created By</div>
                                        <div className="detail-value2">
                                            {userData?.firstName}
                                            {userData?.lastName}
                                        </div>
                                    </div>
                                </div>
                                <div className="detail-item">
                                    <div className="detail-label2">Appointments</div>
                                    <div className="appointments-list">
                                        {matchingAppointments.map((apt, index) => (
                                            <div key={index} className="appointment-item">
                                                <div className="ml-10">
                                                    <span style={{ color: "#7d7d7d", fontWeight: "500" }}>
                                                        {apt.apoointmentDetails?.time} on{" "}
                                                    </span>{" "}
                                                    <span
                                                        style={{
                                                            color: "#3c3c3c",
                                                            fontSize: "14px",
                                                            fontWeight: "800",
                                                        }}
                                                    >
                                                        {apt.apoointmentDetails?.date}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export function AppointmentsDetailComponent({
    appointments,
    handleAppointmentOpen,
    appointmentRefs,
    empNames,
    clientName
}) {
    const sortedDates = Object.keys(appointments).sort((a, b) => new Date(a) - new Date(b));

    return (
        <div>
            {sortedDates.map((date) => {
                const filteredAppointments = appointments[date].filter(appointment => {
                    const clientFilter = clientName === "All" || appointment.clientId === clientName;
                    const empFilter = empNames === "All" || appointment.userId === empNames;
                    return clientFilter && empFilter;
                });

                if (filteredAppointments.length === 0) return null;

                return (
                    <div key={date} ref={(el) => (appointmentRefs.current[date] = el)} className="mb-30" style={{ scrollMarginTop: "85px" }}>
                        <div
                            className="d-flex flex-center pl-10"
                            style={{
                                backgroundColor: "#eef5f9",
                                height: "35px",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                        >
                            {date}
                        </div>

                        {filteredAppointments.map((appointment, index) => (
                            <React.Fragment key={index}>
                                <div
                                    style={{
                                        display: "flex",
                                        marginBottom: "10px",
                                        marginTop: "15px",
                                        position: "relative",
                                        marginLeft: "20px",
                                        marginRight: "5px",
                                        paddingLeft: "12px",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "0",
                                            top: "0",
                                            bottom: "0",
                                            width: "4px",
                                            backgroundColor: "#eef5f9",
                                        }}
                                    ></div>

                                    <div style={{ flex: 1 }}>
                                        <div
                                            className="mv-5"
                                            style={{
                                                color: "#078037",
                                                fontSize: "14px",
                                                fontWeight: "600",
                                            }}
                                            onClick={() => handleAppointmentOpen(appointment)}
                                        >
                                            {appointment.apoointmentDetails.calendar}
                                        </div>
                                        <div>{appointment.apoointmentDetails.calendarTimezone}</div>
                                        <div
                                            className="mt-20"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div className="mv-5">
                                                {appointment.apoointmentDetails?.forms?.[0]?.values?.filter((item) => item.name === "Shift Name")
                                                    ?.map((item) => item.value)} Shift
                                            </div>
                                            <div>{appointment.apoointmentDetails.duration} minutes</div>
                                        </div>
                                        <div className="mb-10">
                                            {appointment.apoointmentDetails.time} - {appointment.apoointmentDetails.endTime}
                                        </div>
                                    </div>
                                </div>
                                {index < filteredAppointments.length - 1 && (
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "1px",
                                            backgroundColor: "#ddd",
                                        }}
                                        className="mv-20"
                                    ></div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};
