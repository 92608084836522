/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CommonContext from "../../hooks/commonContext";
import { Container, Button, TextField, IconButton, Dialog, Typography, Divider, DialogContent, DialogActions } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import { Columns, ColumnsRes } from "./employeeTableColumns";
import TableToolbar from "../../components/Table/TableToolbar";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import { useEffect } from "react";
import {
  getEmployeesList,
  updateEmployee,
} from "../../services/apiservices/employee";
import { useMediaQuery } from "@mui/material";
import ManagePagesCommonCard from "../../components/ResponsiveCardComponent/ManageListPages/ManageListPagesResponsive";
import { getAllAppoinment } from "../../services/apiservices/appoinments";
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';

const ConfirmApptDiaogStatus = ({ open, onConfirm, onClose, rowitem }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="d-flex flex-center flex-space-between">
        <Typography variant="body" className="mt-20 dialogCancelTitle">
          Inactive Employee
        </Typography>
        <IconButton style={{ color: "red", marginLeft: "10px" }} onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <Divider className="mt-15" />
      <DialogContent className="mb-10">
        <span><strong style={{fontWeight:"600"}}>{rowitem.firstName} {rowitem.lastName}</strong> has active or future appointments. Are you sure you want to inactivate this employee?</span></DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
        <Button variant="contained" onClick={onClose} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ConfirmNotApptDiaogStatus = ({ open, onConfirm, onClose, rowitem }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="d-flex flex-center flex-space-between">
        <Typography variant="body" className="mt-20 dialogCancelTitle">
          Inactive Employee
        </Typography>
        <IconButton style={{ color: "red", marginLeft: "10px" }} onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <Divider className="mt-15" />
      <DialogContent className="mb-10">
        <span>You are about to inactivate <strong style={{fontWeight:"600"}}>{rowitem.firstName} {rowitem.lastName}</strong>.
          This is disable all access and the employee will be moved to Inactive Status. Are you sure you want to proceed?
        </span>
      </DialogContent>
      <DialogActions className="mt-20 mb-15">
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
        <Button variant="contained" onClick={onClose} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}


const Employee = (props) => {
  const commonContext = useContext(CommonContext);
  const navigate = useNavigate();
  const [allEmployees, setAllEmployees] = React.useState([]);
  const [appointments, setAppointments] = React.useState();
  const [searchTerm, setsearchTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const [expanded, setExpanded] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogOpenAppts, setDialogOpenAppts] = React.useState(false);
  const [data, setData] = React.useState({});

  const fetchEmployeesList = async () => {
    try {
      setIsLoading(true);

      const [appointments, employeesResponse] = await Promise.all([
        getAllAppoinment(),
        new Promise((resolve, reject) => getEmployeesList({}, resolve, reject))
      ]);
      const today = dayjs().startOf('day');

      const filteredAppointments = appointments.appointments.filter(appointment => {
        const appointmentDateFormatted = dayjs(appointment.apoointmentDetails.date, "MMMM D, YYYY").startOf('day');
        const isSameOrFutureDate = appointmentDateFormatted.isSame(today, 'day') || appointmentDateFormatted.isAfter(today, 'day');
        return isSameOrFutureDate;
      });
      setAppointments(filteredAppointments);

      if (employeesResponse) {
        const data = employeesResponse.filter((item) => !item.deleted && item.status !== false);
        const allEmp = data.map((eitem) => ({
          ...eitem,
          userName: `${eitem?.firstName} ${eitem?.lastName}`,
        }));
        setAllEmployees(allEmp);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchEmployeesList();
  }, []);

  const redirectToCreateEmp = (employeeId) => {
    if (typeof employeeId !== "undefined" && employeeId !== "")
      navigate("/createEmp/" + employeeId);
    else navigate("/createEmp");
  };

  const loginWithEmployee = (empDetails) =>{
    navigate("/ViewEmployeeApp/" + empDetails?.id);
  }

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "email" :
        return (
          <>
            <a  href={`https://mail.google.com/mail/?view=cm&fs=1&to=${rcellItem[hcellItem?.id]}`} rel="noopener noreferrer" target="_blank" className="resLinks" style={{ overflowWrap: "anywhere" }}>
              {rcellItem[hcellItem?.id]}
            </a>
          </>
        );
      case "action":
        return (
          <>
          {rcellItem?.role === 'Employee'? 
          <IconButton
              aria-label="Login"
              color="primary"
              size="small"
              onClick={() => loginWithEmployee(rcellItem)}
            >
              <LockIcon />
            </IconButton>:null}
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={() => redirectToCreateEmp(rcellItem?.id)}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  const tableRightComponent = () => {
    return (
      (isMobile || isLandscape) ? (
        <div className="d-flex flex-direction-column pl-2">
          <div className="d-flex flex-justify-center mb-15">
          <Button
            className="w-norm clientsummry pv-5 fw-bold"
            style={{fontSize:"15px", borderRadius:"2px"}}
            size={"small"}
            variant="contained"
            color="primary"
            onClick={() => redirectToCreateEmp()}
          >
            Add New Employee
          </Button>
          </div>
          <div className="w320">
            <TextField
              fullWidth={true}
              size="small"
              name="searchTerm"
              id="searchTerm"
              label="Search"
              variant="outlined"
              placeholder="Search"
              className="input customSearch mb-15"
              onChange={(event) => {
                setsearchTerm(event.target.value);
              }}
              value={searchTerm ? searchTerm : ""}
            />
          </div>
        </div>
      ) : (
      <div className="d-flex groupsearch">
        <div className="mh-3">
          <TextField
            fullWidth={true}
            size="small"
            name="searchTerm"
            id="searchTerm"
            label=""
            variant="outlined"
            placeholder="Search Employee..."
            className="input"
            onChange={(event) => {
              setsearchTerm(event.target.value);
            }}
            value={searchTerm ? searchTerm : ""}
          />
        </div>
        <Button
          className="flatbutton w-norm"
          size={"small"}
          variant="contained"
          color="primary"
          onClick={() => redirectToCreateEmp()}
        >
          Add New Employee
        </Button>
      </div>
      )
    );
  };

  const handelOnConfirm = () => {
    setIsLoading(true);
      updateEmployee(
        data?.rowitem?.id,
        { status: data?.newstatus },
        (res) => {
          if (res?.status === true) {
            setDialogOpen(false);
            setDialogOpenAppts(false);
            setIsLoading(false);
            setData({});
            navigate("/inactiveEmp");
          } else {
            setIsLoading(false);
            setDialogOpen(false);
            setDialogOpenAppts(false);
            setData({});
          }
        },
        (resError) => {
          setIsLoading(false);
          setDialogOpen(false);
          setDialogOpenAppts(false);
          setData({});
          fetchEmployeesList();
        }
      );
  };

  const handelCloseDialog = () => {
    setDialogOpen(false);
    setData({});
    fetchEmployeesList();
  }
  const handelCloseApptDialog = () => {
    setDialogOpenAppts(false);
    setData({});
    fetchEmployeesList();
  }

  const OnSwitchChange = (rowitem, newstatus) => {
    const data = appointments.filter((item) => item.userId === rowitem?.id);
    if (data && data.length > 0) {
      setDialogOpen(true);
      setData({
        rowitem: rowitem,
        newstatus: newstatus
      })
    } else {
      setDialogOpenAppts(true);
      setData({
        rowitem: rowitem,
        newstatus: newstatus
      })
    }
  };
  const checkRowSearch = (searchTerm, row) => {
    return (
      String(row.acuityId)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.email)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.userName)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.phoneNumber)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase())||
        String(row.employeerole)
          .toLowerCase()
          .includes(String(searchTerm).toLowerCase())
    );
  };
  const filteredList =
    searchTerm !== ""
      ? allEmployees?.filter((item) => checkRowSearch(searchTerm, item))
      : allEmployees;

  const handleExpandClick = (id) => {
    setExpanded(prevExpanded => (prevExpanded === id ? null : id));
  };
    
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Employee"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Employee", to: "/" },
          ]}
          BreadCrumbCurrentPageTitle={"Manage Employee"}
          showLastSeen={true}
        />

        <div className={`sectionBox2`}>
          <TableToolbar
            title={isMobile || isLandscape ? "" : "Employee"}
            rightComponent={tableRightComponent()}
          />
          {(!isMobile && !isLandscape) && 
          <ThemeTable
            rows={filteredList}
            headCells={Columns}
            isLoading={isLoading}
            hidePagination={true}
            renderRowCell={renderRowCell}
            OnSwitchChange={OnSwitchChange}
          />
        } 
        {(isMobile || isLandscape) && (
          <>
            {filteredList.map((record, index) => {
              const isOpen = expanded === record?.id;
              return (
                <ManagePagesCommonCard  
                index={index}
                record={record}
                isOpen={isOpen}
                handleExpandClick={handleExpandClick}
                OnSwitchChange={OnSwitchChange}
                redirectToDetails={redirectToCreateEmp}
                loginWithEmployee={loginWithEmployee}
                columns={ColumnsRes}
                Employee={true}
                />
              );
            })}
            </>
           )}
        </div>
      </Container>
      {dialogOpen &&
        <ConfirmApptDiaogStatus open={dialogOpen} onConfirm={handelOnConfirm} onClose={handelCloseDialog} rowitem={data?.rowitem}/>
      }
      {dialogOpenAppts &&
        <ConfirmNotApptDiaogStatus open={dialogOpenAppts} onConfirm={handelOnConfirm} onClose={handelCloseApptDialog} rowitem={data?.rowitem}/>
      }
    </>
  );
};
export default Employee;
