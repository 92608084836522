import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AuthContext from '../../../hooks/authContext';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CommonContext from '../../../hooks/commonContext';
import { getOneAppointment, updateAppointments } from '../../../services/apiservices/appoinments';
import { AlertOpen, DragReschduleConfirm, TimeZoneConflictsDialog } from '../../../components/DialogComponent/SupplyDataDialog';
import { calculateDurationInMinutes, filterAppointments } from '../../../services/helpers';


const ITEM_TYPE = "APPOINTMENT";

const DraggableAppointment = ({
    index,
    filteredAppointments,
    appointment,
    client,
    boxHeight,
    setIsModalOpen,
    setSelectedAppointment,
}) => {
    const [, drag] = useDrag({
        type: ITEM_TYPE,
        item: { appointment },
    });

    return (
        <div
            ref={drag}
            className="appointmentView"
            style={{
                height: `${boxHeight}px`,
                width: `calc(100% / ${filteredAppointments.length})`,
                left: `${(index % filteredAppointments.length) * (100 / filteredAppointments.length)}%`,
            }}
            onClick={() => {
                setIsModalOpen(true);
                setSelectedAppointment(appointment);
            }}
        >
            <div className="appointmentView-title">{client?.clientName}</div>
            <div className="appointmentView-details2 mv-10">
                {appointment.apoointmentDetails.forms[0]?.values[1]?.value} - {appointment.apoointmentDetails.forms[0]?.values[2]?.value}
            </div>
            <div className="appointmentView-details mv-10">{appointment.apoointmentDetails.shiftName}</div>
            <div className="appointmentView-details2 mv-10">
                {appointment.apoointmentDetails.time} - {appointment.apoointmentDetails.endTime}
            </div>
        </div>
    );
};

const DroppableTimeSlot = ({ hour, day, employee, onDrop, children }) => {
    const [, drop] = useDrop({
        accept: ITEM_TYPE,
        drop: (item) => {
            const { appointment } = item;
            onDrop(appointment, hour, day, employee);
        },
    });

    return <div ref={drop} className="time-slot">{children}</div>;
};

const DayView = ({ allDays, clientDetails, empNames, employeeDetails, ManageAppointmentsData, clientName }) => {
    const timeSlots = Array.from({ length: 13 }, (_, i) => i + 7);
    const timeZones = [
        { label: "EDT - Eastern", value: "America/New_York" },
        { label: "CDT - Central", value: "America/Chicago" },
        { label: "MDT - Mountain", value: "America/Denver" },
        { label: "PDT - Pacific", value: "America/Los_Angeles" }
    ];
    const auth = useContext(AuthContext);
    const appointmentData = auth.appointmentsData;
    const setIsModalOpen = auth.setIsModalOpen;
    const setSelectedAppointment = auth.setSelectedAppointment;
    const [widths, setWidths] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [timeZoneDialog, setTimeZoneDialog] = useState(false);
    const [selectData, setSelectData] = useState();
    const [appointmentsDataState, setAppointmentsDataState] = useState();
    const commonContext = useContext(CommonContext);

    const formatTime = (hour) => {
        const ampm = hour >= 12 ? "pm" : "am";
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}${ampm}`;
    };

    const employeeList = employeeDetails?.data && employeeDetails?.data?.filter((item) =>  item.role === "Employee")

    const gruopData = employeeList ? Object.entries(
        employeeList.reduce((map, employee) => {
            if (!map[employee.timeZone]) {
                map[employee.timeZone] = [];
            }
            map[employee.timeZone].push(employee);
            return map;
        }, {})
    )
        : [];

    useEffect(() => {
        const width = gruopData.flatMap(([timeZone, employees]) =>
            employees.map((employee) => {
                const employeeAppointments = appointmentData.filter(
                    (item) =>
                        item.userId === employee.userId &&
                        moment(item.apoointmentDetails.date).format("MMMM DD YYYY") === moment(allDays).format("MMMM DD YYYY")
                );
                return employeeAppointments.length ? employeeAppointments.length * 300 : 400;
            })
        )
        setWidths(width)
    }, [allDays]);

    const handleDrop = async (appointment, newHour, day, employee) => {
        const inactiveEmp = employeeDetails?.data?.find((emp)=> emp?.userId === appointment?.userId);
        const currentDateTime = moment().utc();
        const appointmentDateTime = moment.utc(
            `${appointment?.apoointmentDetails?.date} ${appointment?.apoointmentDetails?.time}`,
            "MMMM DD, YYYY hh:mm A" 
        );
        const isDateValid = appointmentDateTime.isSameOrAfter(currentDateTime);
        if (appointment.source !== "proteams" || !isDateValid || inactiveEmp.status === false) {
            setAlertOpen(true);
            return;
        } else {
            const data = await getOneAppointment(appointment?.id)
            setSelectData(data.appointment);
            const startHour = moment({ hour: newHour });
            const duration = appointment.apoointmentDetails.duration;
            const endHour = startHour.clone().add(duration, "minutes");

            const updatedAppointment = {
                ...data.appointment,
                time: startHour.format("hh:mm A"),
                endTime: endHour.format("hh:mm A"),
                type: "One Time",
                userId: employee.userId,
                calendar: `${employee.firstName} ${employee.lastName}`,
                calendarTimezone: employee.timeZone
            };

            setAppointmentsDataState(updatedAppointment);
            setDialogOpen(true);
        }
    };

    const handleConfirmCloseDialog = () => {
        setDialogOpen(false);
        setAppointmentsDataState();
        setSelectData(null);
    }

    const handelTimezoneConfirmcancel = () => {
        setTimeZoneDialog(false);
        setAppointmentsDataState();
        setSelectData(null);
    }

    const handelAlertClose = () => {
        setAlertOpen(false);
        setAppointmentsDataState();
        setSelectData();
    }

    const handleConfirmUpdateDialog = async () => {
        const oldTimezone = timeZones.find((item) => item.value === selectData.calendarTimezone)?.label;
        const newTimezone = timeZones.find((item) => item.value === appointmentsDataState.calendarTimezone)?.label;
        const timeZoneConflict = oldTimezone !== newTimezone;

        setDialogOpen(false);
        if (timeZoneConflict) {
            setTimeZoneDialog(true);
        } else {
            commonContext?.setLoader(true);
            const ids = appointmentsDataState?.id;
            const response = await updateAppointments(appointmentsDataState, null, ids);
            if (response.success) {
                commonContext?.setLoader(false);
                setAppointmentsDataState();
                setSelectData(null);
                await ManageAppointmentsData();
            } else {
                console.error("Failed to update appointment:", response.error);
                commonContext?.setLoader(false);
                setDialogOpen(false);
                setAppointmentsDataState();
                setSelectData(null);
            }
        }
    }

    const handelClickTimezoneConfirm = async () => {
        setTimeZoneDialog(false);
        commonContext?.setLoader(true);
        const ids = appointmentsDataState?.id;
        const response = await updateAppointments(appointmentsDataState, null, ids);
        if (response.success) {
            commonContext?.setLoader(false);
            setTimeZoneDialog(false);
            setAppointmentsDataState();
            setSelectData(null);
            await ManageAppointmentsData();
        } else {
            console.error("Failed to update appointment:", response.error);
            commonContext?.setLoader(false);
            setTimeZoneDialog(false);
            setAppointmentsDataState();
            setSelectData(null);
        }
    }

    const TimeColumn = () => {
        return (
            timeSlots.map((hour, index) => (
                <div key={`time-slot-${index}`} className="time-column">
                    {formatTime(hour)}
                </div>
            ))
        );
    };

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <div className="calendar-container d-flex">
                    <div className='newTimeColumn'>
                        <div className="time-column" style={{ height: "38px", borderTop: "2px solid #ddd" }}><AccessTimeIcon fontSize="small" /></div>
                        <TimeColumn />
                    </div>
                    <div className={`calendar-grid ${auth.isExpanded ? "dayExtraWidth" : ""}`}>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: `${widths?.map((width) => `${width}px`).join(" ")}`,
                            }}
                        >
                            {employeeDetails?.data && gruopData?.map(([timeZone, employees], index) => (
                                employees.map((employee) => (
                                    <div key={employee.userId} className="daily-header">
                                        {employee.firstName} {employee.lastName} - {timeZones.find((tz) => tz.value === employee.timeZone)?.label.split(" ")[0]}
                                    </div>
                                ))
                            ))}
                        </div>

                        {timeSlots.map((hour) => (
                            <div
                                key={hour}
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: `${widths?.map((width) => `${width}px`).join(" ")}`,
                                }}
                            >
                                {gruopData?.map(([timeZone, employees]) => (
                                    employees.map((employee) => {
                                        const appointmentsForEmployee = appointmentData.filter(
                                            (appointment) =>
                                                appointment.userId === employee.userId &&
                                                moment(appointment.apoointmentDetails.date).format("MMMM DD YYYY") === moment(allDays).format("MMMM DD YYYY")
                                        );

                                        const filteredAppointments = filterAppointments(appointmentsForEmployee, clientName, empNames);

                                        return (
                                            <DroppableTimeSlot
                                                key={`${hour}-${employee.userId}`}
                                                hour={hour}
                                                day={allDays}
                                                employee={employee}
                                                onDrop={handleDrop}
                                            >
                                                {filteredAppointments.map((appointment, index) => {
                                                    const appointmentTimeSlot = moment(appointment.apoointmentDetails.time, "hh:mm A").hour();
                                                    const client = clientDetails.find((client) => client.id === appointment.clientId || appointment.apoointmentDetails.clientId);
                                                    const appointmentStartTime = appointment.apoointmentDetails.time;
                                                    let appointmentEndTime = appointment.apoointmentDetails.endTime;
                                                    const cutoffTime = moment("8:00 pm", "h:mm a");
                                                    const endTimeMoment = moment(appointmentEndTime, "h:mm a");
                                                    if (endTimeMoment.isAfter(cutoffTime)) {
                                                        appointmentEndTime = cutoffTime.format("h:mm a");
                                                    }
                                                    const duration = calculateDurationInMinutes(appointmentStartTime, appointmentEndTime)
                                                    const boxHeight = (duration / 60) * 100;

                                                    return appointmentTimeSlot === hour ? (
                                                        <DraggableAppointment
                                                            key={`appointment-${appointment.id}`}
                                                            index={index}
                                                            filteredAppointments={filteredAppointments}
                                                            appointment={appointment}
                                                            client={client}
                                                            boxHeight={boxHeight}
                                                            setIsModalOpen={setIsModalOpen}
                                                            setSelectedAppointment={setSelectedAppointment}
                                                        />
                                                    ) : null;
                                                })}
                                            </DroppableTimeSlot>
                                        );
                                    })
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </DndProvider>
            {dialogOpen && (
                <DragReschduleConfirm
                    open={dialogOpen}
                    onSingleAppt={handleConfirmUpdateDialog}
                    auth={selectData}
                    onClose={handleConfirmCloseDialog}
                    client={clientDetails}
                    data={appointmentsDataState}
                    timeZones={timeZones}
                />
            )}
            {timeZoneDialog && (
                <TimeZoneConflictsDialog
                    open={timeZoneDialog}
                    onConfirm={handelClickTimezoneConfirm}
                    onClose={handelTimezoneConfirmcancel}
                />
            )}
            {alertOpen && (
                <AlertOpen
                    open={alertOpen}
                    onClose={handelAlertClose}
                />
            )}
        </>
    );


};

export default DayView;