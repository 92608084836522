import { collection, getDocs, doc, getDoc, addDoc, setDoc, deleteDoc, query, where, deleteField } from 'firebase/firestore';
import { firebase } from '../firebase-config';
import { BaseUrl, ISSUE_DOMAIN, COLLECTIONS } from '../config';
import { getLoginToken } from '../storage';
import { datetimest, getuniqueId } from '../helpers';
import axiosInstance from '../axios';
import dayjs from "dayjs";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

/**
 * Get List of issue
 * @param {*} onSuccess
 * @param {*} onError
 */

function updateEmailList(employees, existingEmails, newEmails) {
  const validEmails = new Set(
    employees
      .filter((record) => record.status === false || record.deleted)
      .map((record) => record.email)
  );

  const filteredEmails = existingEmails.filter((email) => !validEmails.has(email));
  const emailList = Array.from(new Set([...newEmails, ...filteredEmails]));
  return emailList;
}

const collectionRef = collection(firebase, COLLECTIONS.ISSUES);

/**
 * Get List of issues
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getIssuesList(onSuccess, onError) {
  try {
    const querySnapshot = await getDocs(collectionRef);
    let results = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      results.push({ id: doc.id, ...data });
    });
    onSuccess && onSuccess(results);
  } catch (error) {
    console.log('Error getting documents: ', error);
    onError && onError(error);
  }
}

export async function getIssuesByDate(formData, onSuccess, onError) {
  try {
    const startTimestamp = dayjs(formData.startDate).valueOf();
    const endTimestamp = dayjs(formData.endDate).valueOf(); 

    const filteredQuery = query(
      collectionRef,
      where("createdOn", ">=", startTimestamp),
      where("createdOn", "<=", endTimestamp)
    );

    const querySnapshot = await getDocs(filteredQuery);
    let results = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      results.push({ id: doc.id, ...data });
    });
    
    onSuccess && onSuccess(results);
    return results;
  } catch (error) {
    console.log("Error getting documents: ", error);
    onError && onError(error);
  }
}

/**
 * Get Issue detail by issue id
 * @param {*} id  : issueId
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getOneIssue(id, onSuccess, onError) {
  try {
    const docRef = doc(collectionRef, id);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      onError && onError({ status: false, error: 'Issue not found' });
      return;
    }
    let data = docSnap.data();
    onSuccess && onSuccess({ status: true, data: { ...data, id: docSnap.id } });
  } catch (error) {
    onError && onError({ status: false, error: error });
  }
}

/**
 * Add new issue to database with attachments
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function addIssue(formData, onSuccess, onError) {
  try {
    const auth = getAuth();
    const currentUserId = auth.currentUser?.uid;

    let allattachments = [];
    if (formData.attachments && formData.attachments.length > 0) {
      const promises = formData.attachments.map(async (file) => {
        let filename = new Date().getTime() + file.name;
        return uploadFile(file, filename);
      });
      allattachments = await Promise.all(promises);
    }

    formData.attachments = allattachments;
    const docRef = await addDoc(collectionRef, {
      ...formData,
      createdBy: currentUserId,
      createdAt: datetimest(),
    });

    const responseGroupItem = { ...formData, id: docRef.id };
    onSuccess && onSuccess({ status: true, data: responseGroupItem });
  } catch (error) {
    console.log(`ERROR: ${COLLECTIONS.ISSUES} ${error}`);
    onError && onError({ status: false, error: error });
  }
}

/**
 * Update data of particular Issue
 * @param {*} id : issueId
 * @param {*} formData : only pass Data to be updated
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function updateIssue(id, formData, onSuccess, onError) {
  try {
    const auth = getAuth();
    const currentUserId = auth.currentUser?.uid;
    let documentRef = doc(collectionRef, id);
    let allattachments = [];
    if (formData.attachments && formData.attachments.length > 0) {
      const promises = formData.attachments.map(async (file) => {
        let filename = new Date().getTime() + file.name;
        return uploadFile(file, filename);
      });
      allattachments = await Promise.all(promises);
    }

    formData.attachments = allattachments;
    const docContent = {
      ...formData,
      updatedAt: datetimest(),
      updatedBy: currentUserId,
    };
    await setDoc(documentRef, docContent, { merge: true });

    onSuccess && onSuccess({
      status: true,
      message: 'Updated successfully',
    });
  } catch (error) {
    if (error.code === 'not-found') {
      onError && onError({ status: false, message: 'Not Found' });
    } else {
      onError && onError({ status: false, error: error });
    }
  }
}

/**
 * Permanently Remove Issue from database
 * @param {*} id
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function deleteIssue(id, onSuccess, onError) {
  try {
    const documentRef = doc(collectionRef, id);
    await deleteDoc(documentRef);
    onSuccess && onSuccess({ status: true, message: 'Delete successful' });
  } catch (error) {
    onError && onError({ status: false, error: error });
  }
}

/**
 * Upload file to firestore
 * @param {*} file  : file blob, object
 * @param {*} filename : name of file
 * @returns : data of file after uploaded, including download url, type, size
 */

export const uploadFile = (file, filename) => {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const storageRef = ref(storage, `${currentUser?.uid}/${filename}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.log('Upload error', error);
        reject(error);
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        resolve({
          url: url,
          contentType: uploadTask.snapshot.metadata.contentType,
          name: uploadTask.snapshot.metadata.name,
          size: uploadTask.snapshot.metadata.size,
        });
      }
    );
  });
};

/**
 * Add Response to Issue  (called by admin or user)
 * @param {*} id : IssueID
 * @param {*} formData : Response Data to be saved in responses
 * @param {id:'',name:''} respondBy : Name and id of user who adding response
 * @param {*} onSuccess : success callback function
 * @param {*} onError : error callback function
 */
export async function addIssueResponse(
  id,
  formData,
  respondBy,
  issueId,
  alternativeData,
  employees,
  onSuccess,
  onError
) {
  const auth = getAuth();
  const currentUserId = auth.currentUser?.uid;
  const documentRef = doc(firebase, COLLECTIONS.ISSUES, id);
  const docSnapshot = await getDoc(documentRef);
  const existingData = docSnapshot.exists() ? docSnapshot.data() : {};

  const newid = getuniqueId('res');
  let allAttachments = [];

  if (formData.attachments && Array.isArray(formData.attachments) && formData.attachments.length > 0) {
    try {
      const uploadPromises = formData.attachments.map(async (file) => {
        const filename = `${Date.now()}_${file.name}`;
        return await uploadFile(file, filename);
      });
      allAttachments = await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Error uploading files:', error);
      onError && onError({ status: false, message: 'Error uploading attachments' });
      return;
    }
  }

  formData.attachments = allAttachments;

  const emailString = String(formData.to || "").trim();
  const newEmails = emailString ? emailString.split(",").map(email => email.trim()) : [];
  const existingEmails = existingData.email || [];
  const emailList = Array.from(new Set([...existingEmails, ...newEmails]));
  const updatedEmailLists = updateEmailList(employees, existingEmails, newEmails);

  const sendFormData = {
    ...existingData.responses,
    [newid]: {
      ...formData,
      respondBy,
      respondOn: datetimest(),
    },
  };

  const paddedIssueId = String(issueId).padStart(4, '0');

  const docContent = {
    status: 'Pending',
    email: emailList,
    responses: sendFormData,
    updatedAt: datetimest(),
    updatedBy: currentUserId,
    ...(formData.escalated && {
      status: formData.escalatedTo,
      escalated_to_admin: formData.escalatedTo === "Escalate"
    }),
  };
  // const domain = window.location.hostname;
  const url = `${ISSUE_DOMAIN}${id}`;
  try {
    await setDoc(documentRef, docContent, { merge: true });

    if (emailString) {
      const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
      const emailContent = alternativeData.previousMessage ?
        `<p>Newest Message - Issue ${paddedIssueId} (${alternativeData.newTimestamp})</p>

          <p>${alternativeData.currentMessage}</p> 
                     
          <pre>
          
          
          
          

          </pre>

          <p>#############</p>

          <p>Previous Message - Issue ${paddedIssueId} (${alternativeData.previousTimestamp})</p>
        
          <p>${alternativeData.previousMessage}</p>

          <p>&nbsp;</p>

          <p>To see a full list of messages on this issue, please use this <a href='${url}' target='_blank' style="color: blue;">link</a>.</p>

          <p style="color:red";>Please note, replies to this email will be forwarded to all parties currently associated with this ticket.</p>`

        : `<p>Newest Message - Issue ${paddedIssueId} (${alternativeData.newTimestamp})</p>

          <p>${alternativeData.currentMessage}</p>

          <p>&nbsp;</p>

          <p>To see a full list of messages on this issue, please use this <a href='${url}' target='_blank' style="color: blue;">link</a>.</p>
          
          <p style="color:red";>Please note, replies to this email will be forwarded to all parties currently associated with this ticket.</p>`

      await axiosInstance.post(`${BaseUrl}sendEmailData`, {
        email: updatedEmailLists.join(', '),
        subject: `(${alternativeData.clientNames}) ProTeams Issue - #${paddedIssueId}`,
        contenttxt: emailContent,
        issues: true,
      }, {
        headers: {
          'Content-Type': 'application/json',
          withCredentials: true,
          ...authHeaders,
        },
      });
    }

    onSuccess && onSuccess({
      status: true,
      message: 'Updated successfully',
      response: docContent,
    });
  } catch (error) {
    console.error('Error updating document:', error);
    onError && onError({
      status: false,
      message: error.code === 'not-found' ? 'Not Found' : 'An error occurred while updating the document',
      error,
    });
  }
}

/**
 * Close Issue by setting status to Closed
 * @param {*} id : issueId
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function closeIssueApi(id, onSuccess, onError) {
  const auth = getAuth();
  const currentUserId = auth.currentUser?.uid;
  const docRef = doc(collectionRef, id);

  const docContent = {
    status: 'Closed',
    archive: true,
    closedOn: datetimest(),
    updatedAt: datetimest(),
    updatedBy: currentUserId,
  };

  try {
    await setDoc(docRef, docContent, { merge: true });
    onSuccess && onSuccess({ status: true, message: 'Updated successfully' });
  } catch (error) {
    if (error.code === 'not-found') {
      onError && onError({ status: false, message: 'Not Found' });
    }
    onError && onError({ status: false, error: error.code });
  }
}

/**
 * Unarchive Issue by setting status to Pending
 * @param {*} id : issueId
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function unArchiveIssue(id, onSuccess, onError) {
  const auth = getAuth();
  const currentUserId = auth.currentUser?.uid;
  const docRef = doc(collectionRef, id);

  const docContent = {
    status: 'Pending',
    archive: false,
    closedOn: deleteField(),
    updatedAt: datetimest(),
    updatedBy: currentUserId,
  };

  try {
    await setDoc(docRef, docContent, { merge: true });
    onSuccess && onSuccess({ status: true, message: 'Updated successfully' });
  } catch (error) {
    if (error.code === 'not-found') {
      onError && onError({ status: false, message: 'Not Found' });
    }
    onError && onError({ status: false, error: error.code });
  }
}

/**
 * Get Issue List Count based on status
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getIssueListCount(formData, onSuccess, onError) {
  const colRef = collection(firebase, COLLECTIONS.ISSUES);

  try {
    const q = query(colRef, where('status', 'in', [...formData.status]));
    const querySnapshot = await getDocs(q);

    let statusCount = {};
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      statusCount[data?.status] = statusCount[data?.status] ? statusCount[data?.status] + 1 : 1;
    });

    formData.status.forEach(status => {
      if (!statusCount[status]) {
        statusCount[status] = 0;
      }
    });

    onSuccess && onSuccess([statusCount]);
  } catch (error) {
    console.log('Error getting documents: ', error);
    onError && onError(error);
  }
}

/**
 * Update Bold Status for the Issue
 * @param {*} id
 * @param {*} storedData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function updateBoldStatus(id, storedData, onSuccess, onError) {
  if (!id || id.trim() === "") {
    onError && onError({ status: false, message: "Id is Missing" });
    return;
  }

  try {
    const colRef = collection(firebase, COLLECTIONS.ISSUES);
    const updateData = {
      storedData: storedData,
      updatedAt: datetimest(),
    };

    const docRef = doc(colRef, id);
    await setDoc(docRef, updateData, { merge: true });
    onSuccess && onSuccess({ status: true, data: storedData });

  } catch (error) {
    console.error("Error updating document:", error);
    onError && onError({ status: false, message: error.message });
  }
}